import { FC } from 'react';
import { AutocompleteInput, ReferenceInput, required, TextInput, useGetList } from 'react-admin';
import _ from 'lodash';

import { AppOptionRenderer } from './AppOptionRenderer';
import { DurationInput } from './DurationInput';

interface TrialAppFormProps {
  record?: any;
}

export const TrialAppForm: FC<TrialAppFormProps> = ({ record }) => {
  const AppInput = () => {
    const { data } = useGetList('apps');
    const allApps = data?.map((d) => d.id);
    const availableApps = _.difference(allApps, record.app_ids);

    return (
      <ReferenceInput source="app_id" reference="apps" filter={{ id: availableApps }}>
        <AutocompleteInput
          source="app_id"
          optionText={<AppOptionRenderer />}
          inputText={(option) => option.translated_name}
          matchSuggestion={(filter, choice) => choice.translated_name.toLowerCase().includes(filter.toLowerCase())}
        />
      </ReferenceInput>
    );
  };

  return (
    <div>
      <AppInput />
      <DurationInput dataSource="trial_durations" validate={required()} />
      <TextInput
        source="configuration_id"
        type="hidden"
        sx={{ display: 'none' }}
        label={false}
        defaultValue={record?.configuration_key}
      />
    </div>
  );
};
